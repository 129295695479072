@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.contact {
  text-align: center;

  &__text {
    opacity: .5;
    font-size: 16px;
    max-width: 500px;
    color: #000;
    margin: 15px auto;
    display: block;
  }
  form {
    padding: 20px 15px;
    border-radius: 6px;
    background: $darkBox;
    max-width: 700px;
    display: block;
    margin: 20px auto;

    input, textarea {
      margin: 0;
      box-shadow: none;
      outline: none;
      font-family: $mainSansSerif;
      font-size: 16px;
      color: #000;
      padding: 15px !important;
      border-radius: 0;
      outline: none !important;
      box-shadow: none !important;
    }

    .ant-form-item-explain {
      font-size: 10px;
      text-align: left;
      margin-top: 5px;
    }
    .btn {
      display: block;
      padding: 15px 30px;
      font-size: 16px;
      width: 150px;
      margin: 0 auto;
    }
  }
  .recaptcha {
    > div {
      > div {
        margin: 20px auto 0;
      }
    }
  }
}
.news-img {
  width: 450px;
  margin: 0 auto;
  max-width: 95%;

  img {
    width: 100%;
    object-fit: contain;
  }
}
.news-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  @include media('<1024px') {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.news-preview {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  overflow: hidden;
  background: $primaryColor;
  color: #fff;
  text-align: center;
  padding-bottom: 15px;
  transition: all .2s ease;

  @include media('<1024px') {
    font-size: 12px;
  }

  &__img {
    height: 230px;
    width: 100%;

    @include media('<1024px') {
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    text-align: center;
    font-family: $titleFont;
    text-transform: uppercase;
    padding: 0 15px;
    padding-top: 15px;

    @include media('<1024px') {
      font-size: 16px;
    }
  }

  &:hover {
    color: #fff;
    opacity: .85;
  }
}
.news-content {
  background: $primaryColor;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid $primaryColor;

  &__content {
    padding: 20px;
  }

  &__img {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    @include media('<1024px') {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    color: #fff;
  }

  &__text {
    opacity: .85;
    color: #fff;
  }
}
