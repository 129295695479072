$mainBannerHeight: 50vh;
$mainBannerMinHeight: 420px;
$mainWrapTopPadding: clamp($mainBannerMinHeight, $mainBannerHeight, $mainBannerHeight);

// BT-partners Colors:
$primaryColor: #C14237;
$secondaryColor: #FF991A;
$grayText: #B9C5E1;
$darkInput: #3D455A;
$grayFooterText: #97A4C3;
$darkButton: #1D222F;
$dark: #F5F5F5;
$darkBox: #fff;
$dark50: #353D52;
$darkBlue: #1F326C;
$white: #FFFFFF;
$teal: #3CCC87;
$darkGradient: linear-gradient(0deg, #1B2124 0%, transparent 100%);
$primaryColorGradient: linear-gradient(180deg, #FFC306 0%, #FF931D 100%);;
$primaryColorGradientReverse: linear-gradient(180deg, #FF931D 0%, #FFC306 100%);;
$gradientGreen: linear-gradient(81.25deg, #2DD078 -0.52%, $teal 99.56%);
$grayGradient: linear-gradient(136.18deg, #292F41 28.25%, #464F66 98.65%);
$grayGradient2: linear-gradient(146.6deg, #2B3141 22.92%, #464F66 80.06%), #3C455C;
$bannerGradient: linear-gradient(0deg, $darkBlue, $darkBlue), #FF9E2C;

$fontPrimary: "Inter", sans-serif;

// FONTS
$mainSansSerif: "Montserrat-Medium", sans-serif;
$mainSansSerifBold: "Montserrat-Bold", sans-serif;
$titleFont: "Oswald-Bold", sans-serif;;
$subtitleFont: foco;
$focoReg: foco;
//

$primaryMain: #f17d32;
$primaryActiveColor: #e67032;
$secondaryMain: #0066ff;
$tertiaryMain: #e5f0ff;
$tertiaryActiveColor: #0066ff;
$defaultMain: #e9f7f0;
$successStatus: #27ae60;
$errorStatus: #e42320;
$defaultActiveMain: #27ae60;
$defaultActiveMainTransparent: rgba(39, 174, 96, 0.1);
$stroke: #e0e8f5;
$popup_text: #bfcade;
$alwaysWhite: #fff;
$alwaysBlack: #03060e;
$alwaysGrey: #bbc4cd;
$darkGreyColor: #93969d;
$greyMain: #8295a6;
$error_color: #ed5566;
$main_bg: #f4f7fc;
$white_bg: #fff;
$lightsteelblue_bg: #bfcade;
$dart_blue: #21252f;
$primaryBtnBg: $primaryMain;
$primaryBtnBgHover: #ff9d5e;
$primaryBtnBgPressed: #c65f1c;
$primaryBtnBgDisabled: #fbd8c2;
$primaryBtnShadow: rgba(244, 125, 32, 0.3);
$primaryOpacity10: rgba(244, 125, 50, 0.1);
$defaultActiveOpacity10: rgba(39, 174, 96, 0.1);
$secondaryBtnBg: #fef2ea;
$secondaryBtnColor: $white_bg;
$secondaryBtnBgHover: #dbe6f5;
$secondaryBtnShadow: rgba(229, 240, 255, 0.65);
$tertiaryBtnBg: $tertiaryMain;
$tertiaryBtnColor: $tertiaryActiveColor;
$tertiaryBtnDisabled: $main_bg;
$defaultBtnBg: $defaultMain;
$defaultBtnColor: $defaultActiveMain;
$defaultBtnBgHover: #e0eee7;
$defaultBtnShadow: rgb(213, 234, 224);
$sidebarIconColor: $primaryMain;
$currencyColor: #6fcf97;
$fieldsActiveBorderColor: $lightsteelblue_bg;
$fieldsActiveBoxShadow: rgba(223, 232, 245, 0.5);
$switchDisabledBtn: $alwaysGrey;
$switchBgColor: $darkGreyColor;
$promoCardBoxShadow: 0px 5px 20px rgba(204, 215, 234, 0.3);
$promoCardActiveBoxShadow: 0px 10px 30px rgba(204, 215, 234, 0.7);
$radioBtnActive: #1890ff;

$defaultWhite: #fff;
$mainOrange: #F47D20;
$titleBlack: #0E0303;
$backgroundGray: #edf4ff;
$dotBlue: #0066FF;
$opaqueBlack: rgba(0, 0, 0, 0.2);
$opaqueBlack2: rgba(0, 0, 0, 0.5);
$opaqueGray: rgba(240, 240, 240, 0.4);
$opaqueGray2: rgba(213, 213, 213, 0.4);
$opaqueGray3: rgba(130, 149, 166, 0.2);
$borderBlue: rgba(0, 102, 255, 0.6);
$modalBackground: rgba(0, 102, 255, 0.8);
$slideBoxShadow: rgba(0, 102, 255, 0.15);
$backgroundLightBlue: rgba(0, 102, 255, 0.03);
$btnBoxShadowTint: rgba(244, 125, 32, 0.3);
$mainButtonGradient: linear-gradient(75.96deg, #F17D32 3.27%, #F7C443 98.82%);
$mainButtonGradientActive: linear-gradient(170.96deg, #f7cc4c 5.27%, #f18e36 96.82%);
$hollowBtnHoverGradient: linear-gradient(90deg, #F17D32 24.44%, #F7C443 85%);
$underlineGradient: linear-gradient(90deg, #F47D20 -0.85%, #F4B920 51.22%);
$dropdownMenuShadow: rgba(204, 215, 234, 0.8);
$grayBackground: #22282F;

$media_lg: 1279px;
$media_md: 1023px;
$media_xs: 567px;